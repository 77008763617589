<template>
    <div>
        <vue-recaptcha
            :sitekey="gRecaptchaKey"
            size="invisible"
            @verify="onRecaptchaVerify"
            @expired="onRecaptchaExpired"
            @error="onRecaptchaError"
            ref="recaptcha"
        />
        <input type="hidden" name="g-recaptcha-response" :value="value" />
        <p class="text-secondary">
            Este site é protegido pelo reCAPTCHA, sendo aplicáveis a
            <a href="https://policies.google.com/privacy">Política de Privacidade</a> e os
            <a href="https://policies.google.com/terms">Termos de Serviço</a> do Google.
        </p>
    </div>
</template>

<script>
    import { VueRecaptcha } from 'vue-recaptcha';

    export default {
        name: 'Captcha',
        components: {
            VueRecaptcha,
        },
        props: {
            value: {
                default: null,
            },
        },
        data() {
            return {
                gRecaptchaKey: '6LeJ7boqAAAAAA6-1dOz7vaFaLuummobxGd5QXyb',
            };
        },
        methods: {
            onRecaptchaVerify(response) {
                this.$emit('input', response);
            },
            onRecaptchaExpired() {
                this.$emit('input', null);
            },
            onRecaptchaError(e) {
                this.$emit('input', null);
                this.$emit('error', 'Erro ao verificar reCaptcha. Tente novamente.');
            },
            executeRecaptcha() {
                return this.$refs.recaptcha.execute();
            },
            resetRecaptcha() {
                this.onRecaptchaExpired();
                return this.$refs.recaptcha.reset();
            },
        },
    };
</script>
